<template>
  <modal-layout @close="$emit('close')" :title="`Оплатите страховку online!`">
    <div class="documents-list">
      <h1>
        Оплатите страховку online!
      </h1>
      <ol>
        <li>Введите номер договора</li>
        <li>Проверьте указанные данные</li>
        <li>подтвердите и укажите реквизиты Вашей платежной карточки</li>
      </ol>
      <p class="mt-3">
        В случае возникновения вопросов, обращайтесь по номеру <b class="fw-bold">797 (с мобильного бесплатно)</b>.
      </p>
    </div>

    <button class="primary-btn bottom-page-btn" @click="$emit('close')">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "DocumentsPaymentContract",
  props: ['data']
}
</script>

<style lang="scss" scoped>
.documents-list {
  padding: 2rem;
  * {
    font-size: 16px;
  }
  .list {
    list-style: disc;
    margin-bottom: 16px;
  }
}
</style>
