<template>
  <secondary-page-layout :title="$t('pay')" :displayedNavigation="true">
    <v-dialog/>

		<div class="title-wrap d-flex justify-content-between align-items-center">
      <h5 class="title">{{$t('payContractTitle')}}</h5>
      <icon icon-name="read-info-icon" @click.native="openDocumentsModal"></icon>
    </div>
    <div class="form-group">
      <input-field
          :is-block="true"
          :label="$t('policyNumber')"
          :addition="error"
          mask="############"
          v-model="value"
      />
    </div>
    <div class="wrap-form-payment ">
      <a @click.prevent="goToDetail" class="primary-btn">{{$t('payContractBtn')}}</a>
    </div>
  </secondary-page-layout>
</template>
<script>
	import DocumentsPaymentContract from "@/components/Modals/DocumentsPaymentContract";
	import InputField from "@/components/BuyPolicy/InputField";

	export default {
		components: {
		 	DocumentsPaymentContract,
		 	InputField
		},
		data(){
			return{
				value: null,
				text: '<p>Можно приложить не все документы, при этом, заявка будет рассматриваться дольше:&nbsp;<br></p><p>1) Фото удостоверения личности;</p><p>2) Фото техпаспорта пострадавшего транспортного средства; Материалы ГАИ (протокол и схема происшествия ДТП)</p><p>3) Фото постановления суда (после решения суда можно доложить);</p><p>4) Сведения о банковских реквизитах получателя выплаты (фото, PDF или текстовый файл);</p><p><strong>При наличии пострадавших, которым причинен вред жизни или здоровью дополнительно необходимо предоставить:</strong><br></p><p>- Фото больничного листа/ выписной эпикриз и т.п.</p><p>- Фото фискальных, товарных чеков;</p><p>- Фото справки об установлении инвалидности;</p><p>- Фото свидетельства о смерти (или нотариально заверенной копии).&nbsp;</p>',
        error: null
			}
		},
		methods: {
			openDocumentsModal() {
		      this.$modal.show(DocumentsPaymentContract, {data: this.text}, this.$modalConfig)
      },
      async goToDetail() {
        const loader = this.$loading.show();
        this.error = null;

        try{
          await window.axios.get(`/policies/to-payment/${this.value}`)
          await this.$router.push({ name: 'payment-treaty', params: { id: this.value }  });
        }catch (e){
          this.$modal.show('dialog', {
            title: 'Произошла ошибка',
            text: e?.response?.data?.message || e?.message || 'Повторите попытку позднее',
            buttons: [{
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            }]
          });
        }finally {
          loader.hide();
        }
      }
		}
	}
</script>

<style scoped lang="scss">
.pb-80 {
  padding-bottom: 80px;
}
.title-wrap {
	padding: 0px 16px;
	align-items: center;
	margin-bottom: 20px;
  	.title {
	    font-weight: bold;
	    font-size: 14px;
  	}
  }

  	.wrap-form-payment{
		  padding: 0px 16px;
      .input-field {
          background: #FFFFFF;
          box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
          border-radius: 12px;
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
          padding: 16px 16px;
      }
		.primary-btn{
			width: 100%;
		}
	}
</style>